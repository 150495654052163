import {
  Box,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "./productTable.module.scss";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectAllCounterpartsListData } from "../../../../../../_reducers/counterparts.reducer";
import { isUserAuthenticated, authHeader } from "../../../../../../_helpers";
import { counterpartsActions } from "../../../../../../_actions/counterparts.actions";
import axios from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ListSkeleton, SkeletonDashboardV3, SkeletonSelectBox } from "../../../Skeleton/Skeleton";

const ProductTable = ({ onSave }: any) => {
  const [search, setSearch] = React.useState("");
  const [payablesList, setPayablesList] = useState<any>([]);
  const [render, setRender] = React.useState(true);
  const [singleProductID, setSingleProudctID] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [showModal, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(10);
  const [pageneshn, setPagelist] = React.useState(1);
  const [remove, setRemovedata] = React.useState(10);
  const [ascending, setAscending] = React.useState("DESC");
  const [type, setType] = React.useState("");
  const [ascending_date, setAscending_date] = React.useState("DESC");
  const [bulkDeleteData, setBulkDeleteData] = React.useState<readonly string[]>(
    []
  );
  console.log(selected,"selected");
  
  const [TypeSelect, setType_Select] = useState([
    {
      name: "Product",
      value: "product",
    },
    {
      name: "Service",
      value: "service",
    },
  ]);
  const [tebalType, setTebalType] = useState("");
  const dispatch = useDispatch();
  const counterpartData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  function doneTyping() {
    var serdata: any = {
      search: search,
      pagedata: pageneshn,
    };
    // setPagelist(1)
    // setRemovedata(10)
    getCustomer(search, 1, ascending, type,tebalType);
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      doneTyping();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [search]);
  React.useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      getCustomer(search, pageneshn, ascending, type,tebalType);
    }
  }, [render, isUserAuthenticated]);
  React.useEffect(() => {
    // setPagelist(1)
    // setRemovedata(10)
    getCustomer(search, 1, ascending, type,tebalType);
  }, [ascending, type, pageneshn,tebalType]);

  const getCustomer = async (
    search: any,
    pageneshn: any,
    ascending: any,
    type: any,
    tebalType:any
  ) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", ...authHeader() },
    };
    setLoading(true);
    var limet = 10;

    const response = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/products/get-products?page=${pageneshn}&order=${ascending}&orderby=${type}&search=${search}&limit=${limet}&type=${tebalType}`,
        requestOptions
      )
      .then((response) => {
        if (response.data.success == true) {
          setPayablesList(response.data.data.data);
          setTotalCount(response.data.data.total);
          setLoading(false);
        } else {
          console.log("errr");
          setLoading(false);
        }
        console.log(response, "response");
        setLoading(false);
      });
  };
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleShow_PopUp = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };
  const handleClick_datat = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = payablesList.map((n: any) => n.monite_product_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  const handleClick = (moniteId: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    let newBulkDelete: readonly string[] = [];

    // Update the 'selected' state
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // Update the 'bulkDeleteData' state
    const bulkDeleteIndex = bulkDeleteData.indexOf(moniteId);

    if (bulkDeleteIndex === -1) {
      newBulkDelete = newBulkDelete.concat(bulkDeleteData, moniteId);
    } else if (bulkDeleteIndex === 0) {
      newBulkDelete = newBulkDelete.concat(bulkDeleteData.slice(1));
    } else if (bulkDeleteIndex === bulkDeleteData.length - 1) {
      newBulkDelete = newBulkDelete.concat(bulkDeleteData.slice(0, -1));
    } else if (bulkDeleteIndex > 0) {
      newBulkDelete = newBulkDelete.concat(
        bulkDeleteData.slice(0, bulkDeleteIndex),
        bulkDeleteData.slice(bulkDeleteIndex + 1)
      );
    }
    setSelected(newBulkDelete);
    setBulkDeleteData(newBulkDelete);
  };

  const singleDeleteProducts = () => {
    dispatch(counterpartsActions.singleDeleteProducts(singleProductID) as any);
    setTimeout(() => {
      getCustomer(search, pageneshn, ascending, type,tebalType);
    }, 3000);
    setSelected([]);
  };
  const AlldeleteProducts = () => {
    var body = {
      ids: bulkDeleteData,
    };
    dispatch(counterpartsActions.AlldeleteProducts(body) as any);
    setTimeout(() => {
      getCustomer(search, pageneshn, ascending, type,tebalType);
    }, 3000);
    setSelected([]);
  };

  return (
    <>
      {/* <ListSkeleton loader={loading} height={'100vh'} width={'100%'}
                 element={    */}
      <Grid container className={styles.Customers_grid_container}>
        <SkeletonSelectBox
          width={"100%"}
          height={'60px'}
          loader={loading}
          style={{ textAlign: "center"}}
          element={
            <>
              <Grid item md={3} sm={12} xs={12}>
                <InputGroup className="balance__filter-wrapper input">
                  <InputGroup.Text id="basic-addon1" className="icon">
                    <SearchIcon />
                  </InputGroup.Text>
                  <Form.Control
                    className="balance-search-input"
                    placeholder="Search by Name"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    disabled={loading}
                    value={search}
                    onChange={(event) => {
                      if (event.target.value == "") {
                        setSearch(event.target.value);
                      } else {
                        setSearch(event.target.value);
                      }
                    }}
                  />
                </InputGroup>
              </Grid>
              <Grid item md={3} sx={{paddingLeft:"22px"}}>
                <Form.Select
                  name="supplier_id"
                  onChange={(e: any) => {
                    setTebalType(e.target.value);

                    getCustomer(
                      search,
                      pageneshn,
                      ascending,
                      type,
                      e.target.value
                    );
                  }}
                  value={tebalType}
                  className={styles.selectBoxcalss}
                >
                  <option className={styles.optionSelect} selected value="">
                    Select Type
                  </option>
                  {TypeSelect?.map((arrayElement: any, index: number) => {
                    return (
                      <option
                        className={styles.optionSelect}
                        value={arrayElement.value}
                        key={index + 1}
                      >
                        {arrayElement.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Grid>
              <Grid item md={6}></Grid>
            </>
          }
        ></SkeletonSelectBox>
        <Grid item md={12} xs={12}>
        <SkeletonDashboardV3
            width={"100%"}
            height={'450px'}
            loader={loading}
            element={
          <div className="payablegrid__table">
            <TableContainer className={styles.Customers_TableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((el) => (
                      <>
                        {el.id == "Checkbox" ? (
                          <TableCell padding="checkbox">
                            <Checkbox
                              icon={<img src="/Checkbox.svg" />}
                              checkedIcon={<img src="/Checkbox_two.svg" />}
                              color="primary"
                              indeterminate={
                                selected.length > 0 &&
                                selected.length < payablesList.length
                              }
                              checked={
                                payablesList.length > 0 &&
                                selected.length === payablesList.length
                              }
                              onChange={handleSelectAllClick}
                              inputProps={{
                                "aria-label": "select all desserts",
                              }}
                            />
                          </TableCell>
                        ) : el.id == "counterpart_name" ? (
                          <TableCell>
                            <div
                              style={{ display: "flex", cursor: "pointer" }}
                              onClick={() => {
                                if (ascending == "DESC") {
                                  setAscending("ASC");
                                  setType(el.id);
                                  setAscending_date("ASC");
                                } else {
                                  setAscending("DESC");
                                  setAscending_date("DESC");
                                  setType(el.id);
                                }
                              }}
                            >
                              {el.label}
                              {ascending_date == "DESC" &&
                              el.id == "counterpart_name" ? (
                                <ArrowDropDownIcon />
                              ) : (
                                <ArrowDropUpIcon />
                              )}
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell>{el.label}</TableCell>
                        )}
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payablesList?.map((item: any, index: any) => {
                    const isItemSelected = isSelected(item.monite_product_id);
                    return (
                      <TableRow
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        // selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            icon={<img src="/Checkbox.svg" />}
                            checkedIcon={<img src="/Checkbox_two.svg" />}
                            checked={isItemSelected}
                            onClick={(event: any) => {
                              handleClick(item.monite_product_id, item.id);
                            }}
                          />
                        </TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.type=='product'?"Product":item.type=='service'?"Service":item.type}</TableCell>
                        <TableCell>
                          {moment(item.created_at).format("DD-MMM-YYYY")}
                        </TableCell>
                        <TableCell>{item.price}</TableCell>
                        <TableCell>{item.currency}</TableCell>
                        <TableCell>
                          {item.measure_unit?.name ?? "-"}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e: any) => {
                              handleClick_datat(e);
                              setSingleProudctID(item?.monite_product_id);
                            }}
                          >
                            <MenuIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.addnewbtnclass}>
              <Button
                disabled={selected.length > 0 ? false : true}
                onClick={() => AlldeleteProducts()}
                color="#F87168"
                className="white_btn_two"
              >
                Delete All
              </Button>
              {/* <div className={styles.pageNeshan}>
                <div className={styles.inpagenum}>
                  <p>
                    {remove - 9}-{remove}
                  </p>
                </div>
                <div className={styles.btnmaindivin}>
                  {pageneshn == 1 ? (
                    <button disabled className={styles.nextntmistish}>
                      <ArrowBackIosNewIcon style={{ fontSize: "16px" }} />
                    </button>
                  ) : (
                    <button
                      className={styles.nextntmistish}
                      onClick={() => {
                        setPagelist(pageneshn - 1);
                        setRemovedata(remove - 10);
                      }}
                    >
                      <ArrowBackIosNewIcon style={{ fontSize: "16px" }} />
                    </button>
                  )}
                  {remove >= totalCount ? (
                    <button disabled className={styles.nextntmistish}>
                      <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                    </button>
                  ) : (
                    <button
                      className={styles.nextntmistish}
                      onClick={() => {
                        setPagelist(pageneshn + 1);
                        setRemovedata(remove + 10);
                      }}
                    >
                      <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                    </button>
                  )}
                </div>
              </div> */}


  <div className={styles.pageNeshan}>
    <div className={styles.inpagenum}>
      <p>
        {remove - 9}-{Math.min(remove, totalCount)} {/* Ensure range doesn't exceed totalCount */}
      </p>
    </div>
    <div className={styles.btnmaindivin}>
      {pageneshn === 1 ? (
        <button disabled className={styles.nextntmistish}>
          <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
        </button>
      ) : (
        <button
          className={styles.nextntmistish}
          onClick={() => {
            setPagelist((prev) => prev - 1); // Move to the previous page
            setRemovedata((prev) => prev - 10); // Adjust the range
            getCustomer(search, pageneshn - 1, ascending, type, tebalType); // Fetch new page data
          }}
        >
          <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
        </button>
      )}
      {remove >= totalCount ? (
        <button disabled className={styles.nextntmistish}>
          <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
        </button>
      ) : (
        <button
          className={styles.nextntmistish}
          onClick={() => {
            setPagelist((prev) => prev + 1); // Move to the next page
            setRemovedata((prev) => prev + 10); // Adjust the range
            getCustomer(search, pageneshn + 1, ascending, type, tebalType); // Fetch new page data
          }}
        >
          <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
        </button>
      )}
    </div>
  </div>




            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/create-product?id=" + singleProductID, {
                    replace: true,
                    state: { id: singleProductID },
                  });
                }}
                className="menuitem"
              >
                Update
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleShow_PopUp();
                }}
                className="menuitem"
                style={{ color: "#F87161" }}
              >
                Delete
              </MenuItem>
            </Menu>
            <Modal
              show={showModal}
              onHide={onClose}
              backdrop="static"
              keyboard={false}
              aria-labelledby="ModalHeader"
              centered
              size="sm"
            >
              <Modal.Body>
                <Box className={styles.box_dialog_main}>
                  <Typography className={styles.typo_text}>
                    Are you sure you want to remove this product?
                  </Typography>
                  <Box className={styles.box_btn_cencal}>
                    <Button className={styles.cancel_btn} onClick={onClose}>
                      Close
                    </Button>
                    <Button
                      className={styles.delete_btn}
                      onClick={() => {
                        onClose();
                        singleDeleteProducts();
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Modal.Body>
            </Modal>
          </div>}>
          </SkeletonDashboardV3>
        </Grid>
      </Grid>
      {/* }>
            </ListSkeleton> */}
    </>
  );
};
export default ProductTable;
const SearchIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
      stroke="#111111"
      stroke-width="1.1875"
      stroke-miterlimit="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const MenuIcon = () => (
  <svg
    width="4"
    height="16"
    viewBox="0 0 4 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8C0 8.53043 0.210714 9.03914 0.585787 9.41421C0.96086 9.78929 1.46957 10 2 10C2.53043 10 3.03914 9.78929 3.41421 9.41421C3.78929 9.03914 4 8.53043 4 8C4 7.46957 3.78929 6.96086 3.41421 6.58579C3.03914 6.21071 2.53043 6 2 6C1.46957 6 0.96086 6.21071 0.585787 6.58579C0.210714 6.96086 0 7.46957 0 8ZM0 2C0 2.53043 0.210714 3.03914 0.585787 3.41421C0.96086 3.78929 1.46957 4 2 4C2.53043 4 3.03914 3.78929 3.41421 3.41421C3.78929 3.03914 4 2.53043 4 2C4 1.46957 3.78929 0.960859 3.41421 0.585786C3.03914 0.210714 2.53043 0 2 0C1.46957 0 0.96086 0.210714 0.585787 0.585786C0.210714 0.960859 0 1.46957 0 2ZM0 14C0 14.5304 0.210714 15.0391 0.585787 15.4142C0.96086 15.7893 1.46957 16 2 16C2.53043 16 3.03914 15.7893 3.41421 15.4142C3.78929 15.0391 4 14.5304 4 14C4 13.4696 3.78929 12.9609 3.41421 12.5858C3.03914 12.2107 2.53043 12 2 12C1.46957 12 0.96086 12.2107 0.585787 12.5858C0.210714 12.9609 0 13.4696 0 14Z"
      fill="#6D6B6B"
    />
  </svg>
);
interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "Checkbox",
    label: "",
  },
  {
    id: "counterpart_name",
    label: "Name",
  },
  {
    id: "Type",
    label: "Type",
  },
  {
    id: "Created_at",
    label: "Created at",
  },
  {
    id: "Price",
    label: "Price",
  },
  {
    id: "Currency",
    label: "Currency",
  },
  {
    id: "Unit_of_measure",
    label: "Unit of measure",
  },
  {
    id: "menu",
    label: "",
  },
];
