import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import "../Balance/components/topup-modal/Topup.scss";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";

const WhatsNewModal = (props: any) => {
  const [show, setShow] = useState(props.show == true ? true : false);
  const [id, setId] = useState(!!props.id ? props.id : '');
  const [type, setType] =  useState(!!props.type ? props.type : 'default');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [image, setImage] = useState('');
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json'},
  };
  const getContent = async () => {
    if(type === 'default'){
      await axios
      .get(
        `${process.env.REACT_APP_API_URL}/get-latest-feature`,
        requestOptions
      )
      .then((response) => {
        console.log(response,response.data.success == true,"response8745");
        if (response.data.success == true) {
          setTitle(response.data.data.title);
          console.log('Title:', response.data.data.title);
          setBody(response.data.data.main_content);
          console.log('Body:', response.data.data.main_content);
          setImage(response.data.data.image_link);
          console.log('Image:', response.data.data.image_link);
        }
      });
    }else{
      await axios
  .get(
    `${process.env.REACT_APP_API_URL}/get-features`,
    requestOptions
  )
  .then((response) => {
    console.log(response, response.data.success === true, "response8745");
    if (response.data.success === true) {
      // Filter the data to find the object with the desired ID
      const filteredData = response.data.data.find((item: { id: any; }) => item.id === id);
  
      if (filteredData) {
        setTitle(filteredData.title);
        console.log('Title:', filteredData.title);
        setBody(filteredData.main_content);
        console.log('Body:', filteredData.main_content);
        setImage(filteredData.image_link);
        console.log('Image:', filteredData.image_link);
      } else {
        console.log('No data found for the specified ID.');
      }
    }
  });

    }
  
  
}
  const handleClose = () => props.onClose();
  const handleShow = () => setShow(true);

  useEffect(() => {
      getContent();
    
  }, );
  const onClose_topup = () => {
    props.onClose();
  };
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  return (
    <>
    <ThemeProvider theme={theme}>
      <Modal 
          show={show} 
          onHide={handleClose} 
          animation={false}
          backdrop="static"
          keyboard={false}
          aria-labelledby="ModalHeader"
          className="topup-modal"
          centered
          size="lg">   
          <Box className="CloseContenar">
              <IconButton
                onClick={() => {
                  onClose_topup();
                }}
              >
                <CloseIcon />
              </IconButton>
          </Box>
          <Box className="heding_contean">
              <Typography>{title}</Typography>
          </Box>
         
        <Modal.Body style={{ maxHeight: "30rem", overflow: "hidden", overflowY: "scroll" , alignItems: "center", justifyContent: "center", display: "flex",
           flexDirection: "column", textAlign: "center"}}>
          <div>
          {image && <img src={image} alt="Feature Image" style={{ width: '50%', height: 'auto', maxHeight: '50%'}}/>}
          </div>
          <div style={{ textAlign: "center"}}> {body}</div>
        </Modal.Body>
      </Modal>
      </ThemeProvider>
    </>
  );
}

export default WhatsNewModal;