import { Box, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styles from './connectInvoice.module.scss';
import ConnectInvoiceTable from './connectInvoiceTable';
import { useEffect, useMemo, useState } from 'react';
import { dashboardActions } from '../../../../../../../_actions';
import { useDispatch, useSelector } from "react-redux";
import { selectAllData} from "../../../../../../../_reducers/dashboard.reducer";
import { useSearchParams } from "react-router-dom";

const InvoiceConnection = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [invoiceDetail, setInvoiceDetail] = useState([])
  const [moniteInvocieId, setMoniteInvocieId] = useState([])
  const data = useSelector((state) => selectAllData(state));

const theme = createTheme({
        status: {
          danger: "#025041",
        },
 });

 useEffect(() => {
        const id: any = searchParams.get('id');
        setMoniteInvocieId(id)
    }, [searchParams]);


 useEffect(() => {
    if (moniteInvocieId) {
      dispatch(dashboardActions.getInvoicedetels(moniteInvocieId) as any);
    }
  }, [moniteInvocieId])

const memoizedInvoiceData = useMemo(() => data?.GET_INVOICE_BY_ID_SUCCESS, [data?.GET_INVOICE_BY_ID_SUCCESS]);
useEffect(() => {
  if (memoizedInvoiceData !== undefined) {
    setInvoiceDetail(memoizedInvoiceData?.data);
  }
}, [memoizedInvoiceData]);  
    
    return (
        <>
            <ThemeProvider theme={theme}>
                <>
                <Box className={styles.ContenarInvoice}>
                    <Box>
                        <Typography className={styles.typo_heding}>Connect Invoice with Payments</Typography>
                    </Box>
                </Box>
                <Box className={styles.ContenarTebal}>
                    <ConnectInvoiceTable invoiceDetail={invoiceDetail} />
                </Box>
                </>
            </ThemeProvider>
        </>
    )
}
export default InvoiceConnection;