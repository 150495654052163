import { Box, Grid, Typography } from "@mui/material";
import styles from "./customers_details.module.scss";
import moment from "moment";

const Setp1 = ({ Detels, DataArraylog }: any) => {
  console.log(Detels, "DetelsDetels");

  return (
    <>
      <Grid container className={styles.setp_container}>
        <Grid item md={3.9} xs={12} className={styles.grid_fast}>
          <Typography className={styles.heding_box}>Invoice issued</Typography>
          <Box className={styles.contean_box} sx={{ paddingBottom: "10px" }}>
            <Typography className={styles.typo_contean}>
              Total invoices
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{display:"flex",justifyContent:"end"}}>
              {Detels?.total_issued_amount}
            </Typography>
          </Box>
          <Box className={styles.contean_box}>
            <Typography className={styles.typo_contean}>
              Total amount
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{display:"flex",justifyContent:"end"}}>
              {Detels?.currency}{Detels?.total_issued_invoices}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={3.9} xs={12} className={styles.grid_fast}>
          <Typography className={styles.heding_box}> Invoices paid</Typography>
          <Box className={styles.contean_box} sx={{ paddingBottom: "10px" }}>
            <Typography className={styles.typo_contean}>
              Total invoices
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{display:"flex",justifyContent:"end"}}>
              {Detels?.total_paid_amount}
            </Typography>
          </Box>
          <Box className={styles.contean_box}>
            <Typography className={styles.typo_contean}>
              Total amount
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{display:"flex",justifyContent:"end"}}>
              {/* {dataList?.paid?.currancy}  */}
              {Detels?.currency}{Detels?.total_paid_invoices}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={3.9} xs={12} className={styles.grid_fast}>
          <Typography className={styles.heding_box}>
            Invoices overdue
          </Typography>
          <Box className={styles.contean_box} sx={{ paddingBottom: "10px" }}>
            <Typography className={styles.typo_contean}>
              Total invoices
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{display:"flex",justifyContent:"end"}}>
              {Detels?.total_overdue_invoices}
            </Typography>
          </Box>
          <Box className={styles.contean_box}>
            <Typography className={styles.typo_contean} >
              Total amount
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{display:"flex",justifyContent:"end"}}>
              {/* {dataList?.overdue?.currancy} */}
              {Detels?.currency}{Detels?.total_overdue_amount}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box className={styles.box_secend_poshan}>
            <Box className={styles.LineStart}></Box>
            <Typography className={styles.Recent_typo}>
              Recent activity
            </Typography>
            <Box className={styles.box_last_line}></Box>
          </Box>
        </Grid>
        <Grid container className={styles.setp_footer_container}>
          {DataArraylog.map((item: any, index: any) => {
            console.log(item,"itemitem123");
            
            return (
              <>
                {!!item.created_at?<>
                  <Grid item md={2}>
                    <Typography className={styles.name_text}>
                    Invoice Created
                    </Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className={styles.name_text}>
                    Invoice no. {item?.invoice_no} of amount {item?.currency}{item?.total} Created
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography className={styles.name_text}>
                      {moment(item.created_at).format("DD-MMM-YYYY")}
                    </Typography>
                  </Grid>
                </>:""}
                {!!item.submitted_at?
                <>
                  <Grid item md={2}>
                    <Typography className={styles.name_text}>
                    Invoice Submitted
                    </Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className={styles.name_text}>
                    Invoice no. {item?.invoice_no} of amount {item?.currency}{item?.total} Submitted
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography className={styles.name_text}>
                      {moment(item.submitted_at).format("DD-MMM-YYYY")}
                    </Typography>
                  </Grid>
                </>:""}
                {!!item.cancelled_at?
                <>
                  <Grid item md={2}>
                    <Typography className={styles.name_text}>
                    Invoice Cancellated
                    </Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className={styles.name_text}>
                    Invoice no. {item?.invoice_no} of amount {item?.currency}{item?.total} Cancellated
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography className={styles.name_text}>
                      {moment(item.cancelled_at).format("DD-MMM-YYYY")}
                    </Typography>
                  </Grid>
                </>:""}
                {!!item.paid_at?
                <>
                  <Grid item md={2}>
                    <Typography className={styles.name_text}>
                    Invoice  Paid
                    </Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className={styles.name_text}>
                    Invoice no. {item?.invoice_no} of amount {item?.currency}{item?.total} Paid
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography className={styles.name_text}>
                      {moment(item.paid_at).format("DD-MMM-YYYY")}
                    </Typography>
                  </Grid>
                </>:""}
                {!!item.deleted_at?
                <>
                  <Grid item md={2}>
                    <Typography className={styles.name_text}>
                    Invoice  Deleted
                    </Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className={styles.name_text}>
                    Invoice no. {item?.invoice_no} of amount {item?.currency}{item?.total}  Deleted
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography className={styles.name_text}>
                      {moment(item.deleted_at).format("DD-MMM-YYYY")}
                    </Typography>
                  </Grid>
                </>:""}
              </>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};
export default Setp1;
