import Modal from 'react-bootstrap/Modal';
import styles from "./markAsModal.module.scss";
import { Box, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";

interface Props {
  showModal: boolean;
  handleClose: any;
  moniteId: any;
}

const MarkAsPaidModal = (props: Props) => {
    let navigate = useNavigate();
    const handleSelect = (status:any) => {
        if(status === "select_payment"){
            navigate("/invoice/connect-to-payment?id=" + props.moniteId, { replace: true, state: { id: props.moniteId } })
        } else {
            console.log("do nothing!")
        }
    }

const markSelectiondata = [
    {
        slug: "select_payment",
        heading: "Select payment",
        description: "If payment is done from the platefomr you can proceed to select the payment"
    },
    {
        slug: "add_payment",
        heading: "Add a payment",
        description: "If payment is done outside this plateform you can add payment details here"
    }
]

console.log("moniteId modal =>", props.moniteId);

  return (
      <>
      <Modal
        show={props.showModal}
        onHide={props.handleClose}
        keyboard={false}
        size="lg"
        centered
      >  
        <Box className='heding_contean'>
              <Typography className={styles.heading}>Mark invoice as paid</Typography>
            </Box>
            <Modal.Body
              style={{
                maxHeight: '30rem',
                overflow: 'hidden',
                overflowY: 'scroll',
                padding: '30px 30px 40px 30px',
              }}
            >
              <div className={styles.sub_heading}>Choose method to proceed</div>
              <div className="type-card-list">
          {markSelectiondata && (
  <div className={styles.flex_container}>
    {markSelectiondata.map((item: any) => {
      return (
        <div
          key={item.id}
          className={styles.type_card}
          onClick={() =>
          item.slug === "select_payment" ? handleSelect("select_payment") : handleSelect("add_payment")
        }
        >
          <div className="heading_center_data">
            <Typography className={styles.heading_text}>
              {item.heading}
            </Typography>

            <Typography className={styles.desc_text}>
              {item.description}
            </Typography>
          </div>
        </div>
      );
    })}
  </div>
)}

              </div>
            </Modal.Body>
      </Modal>
    </>
  );
};

export default MarkAsPaidModal;
