import './Bill_Detail.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Detail_heder from './components/Detail_hedear/Detail_heder';
import Contean_Bill from './components/contean_bill/contean_bill';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../../../../_actions";
import {
  getBalanceReducer,
  getBillReducer,
  selectAllData,
} from "../../../../../_reducers/dashboard.reducer";
import { Box, CircularProgress } from '@mui/material';
const Invoice_Detail = (props: any) => {
  const [redusar, setRedusar] = useState(false)
  const [Loader, setLoader] = useState(false)
  const [Datadeteld, setDatadeteld] = useState([])
  const data = useSelector((state) => selectAllData(state));
  const [dataset, setData] = useState<any>()
  console.log(Datadeteld, "dataset123");

  const dispatch = useDispatch();

  const theme = createTheme({
    status: {
      danger: "#025041",
    },
  });
  console.log(data, "sadsadd");

  useEffect(() => {
    if (props?.id) {
      dispatch(dashboardActions.getInvoicedetels(props?.id) as any);
      setLoader(true)
      setTimeout(() => (
        setRedusar(true)
      ), 3000)
    }
  }, [props.id])

  useEffect(() => {
    if (redusar && data?.GET_INVOICE_BY_ID_SUCCESS !== undefined) {
      setRedusar(false)
      console.log(data?.GET_INVOICE_BY_ID_SUCCESS, "GET_PAYABLE_BY_ID_SUCCESS123456");
      setDatadeteld(data?.GET_INVOICE_BY_ID_SUCCESS)
      setLoader(false)
    }
  }, [redusar, data])

  const closeModal = () => {
    props.onClose()
  }


  return (

    <>
      <ThemeProvider theme={theme}>
        {Loader && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
          <CircularProgress color="success" />
        </Box>}
        {Datadeteld.length == 0 ? "" : <Detail_heder closeModal={() => {
          props.onClose()
        }} data={Datadeteld} dataset={dataset} id={props?.id} />}
        {Datadeteld.length == 0 ? "" : <Contean_Bill setDATA={setData} data={Datadeteld} />}
      </ThemeProvider>
    </>
  )
}
export default Invoice_Detail;