import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, Box, Typography, Badge } from '@mui/material';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { styled } from '@mui/system';
import moment from 'moment';
import { useNotifications } from './NotificationContext';
import WhatsNewModal from '../app/Layout/Dashboard/whats_new_popup'; 
import styles from "./styles.module.scss";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow: 'rgb(255, 255, 255) 0px 2px 4px 0px inset, rgb(0, 0, 0) 0px 2px 10px 0px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: theme.palette.action.selected,
            },
        },
    },
}));

const WhatsNewComponent = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { updates } = useNotifications();
    const [showBadge, setShowBadge] = useState(true);
    const [modalOpen, setModalOpen] = useState(false); 
    const [selectedId, setSelectedId] = useState(null); 
    const [selectedType, setSelectedType] = useState('default'); 

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setDropdownOpen(true);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setDropdownOpen(false);
        setShowBadge(false);
    };

    const handleMenuItemClick = (id, type = 'default') => {
        setSelectedId(id); 
        setSelectedType(type);
        setModalOpen(true); 
        handleMenuClose(); 
    };

    const handleModalClose = () => {
        setModalOpen(false); 
        setSelectedId(null);
    };

    return (
        <div>
            <div className="notification-bell" onClick={handleMenuOpen}>
                <Badge color="error" variant={showBadge ? "dot" : undefined}>
                    <TipsAndUpdatesOutlinedIcon style={{ color: "white" }} />
                </Badge>
            </div>
            <StyledMenu
                id="notification-menu"
                anchorEl={anchorEl}
                open={dropdownOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'notification-button',
                }}
                className={styles.content_two}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" p={2} borderBottom="1px solid #ccc">
                    <Typography variant="body2" style={{ fontFamily: "Poppins, Verdana", color: "#000000", fontSize: "14px", textAlign: "left" }}>Latest Updates</Typography>
                </Box>
                <Box>
                    {updates.length === 0 ? (
                        <MenuItem>No new updates</MenuItem>
                    ) : (
                        updates
                            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                            .slice(0, 5)
                            .map((notification, index) => (
                                <MenuItem
                                    key={index}
                                    style={{ backgroundColor: "#F5F5F5", cursor: "pointer" }}
                                    onClick={() => handleMenuItemClick(notification.id, 'specific')}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <Box sx={{ ml: 2, flexGrow: 1 }}>
                                            <Typography variant="body2" style={{ fontFamily: "Poppins, Verdana", fontSize: "12px", color: "#025041" }} color="textSecondary">
                                                {notification.title}
                                            </Typography>
                                            <Typography variant="caption" style={{ fontFamily: "Poppins, Verdana", fontSize: "12px" }} color="textSecondary">
                                                {moment(notification.created_at).fromNow()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </MenuItem>
                            ))
                    )}
                </Box>
            </StyledMenu>
            {modalOpen && (
                <WhatsNewModal
                    show={modalOpen}
                    id={selectedId}
                    type={selectedType}
                    onClose={handleModalClose} 
                />
            )}
        </div>
    );
};

export default WhatsNewComponent;
